
.btn{
  @apply inline-flex items-center;
  @apply px-2.5 py-1.5;
  @apply border border-transparent rounded;
  @apply font-normal no-underline;
  @apply transition-all;
  @apply focus:outline-none focus:ring-1 focus:ring-offset-2;

  @apply bg-white hover:bg-neutral-100;
  @apply text-neutral-800 hover:text-neutral-800;
  @apply focus:ring-neutral-300;

  @apply disabled:bg-neutral-200 disabled:text-neutral-400 disabled:cursor-not-allowed;
  @apply disabled:hover:bg-neutral-300 disabled:text-neutral-400;
}

/* Brand colors */
.btn-mtb{
  @apply bg-mtb hover:bg-mtb-600 shadow-sm;
  @apply text-white hover:text-white;
  @apply focus:ring-mtb-300;
}
.btn-emtb{
  @apply bg-emtb hover:bg-emtb-600 shadow-sm;
  @apply text-white hover:text-white;
  @apply focus:ring-emtb-300;
}
.btn-rr{
  @apply bg-rr hover:bg-rr-600 shadow-sm;
  @apply text-white hover:text-white;
  @apply focus:ring-rr-300;
}
.btn-nr{
  @apply bg-nr hover:bg-nr-600 shadow-sm;
  @apply text-white hover:text-white;
  @apply focus:ring-nr-300;
}

/* state colors  */
.btn-danger{
  @apply bg-red-500 hover:bg-red-600 shadow-sm;
  @apply text-white hover:text-white;
  @apply focus:ring-red-300;
}
.btn-link{
  @apply bg-white hover:bg-neutral-100 shadow-sm;
  @apply border border-solid border-neutral-200;
  @apply text-neutral-800 hover:text-neutral-800;
  @apply focus:ring-neutral-300;
}
.btn-success{
  @apply bg-green-600 hover:bg-green-700 shadow-sm;
  @apply text-white hover:text-white;
  @apply focus:ring-green-600;
}
.btn-warning{
  @apply bg-amber-400 hover:bg-amber-500 shadow-sm;
  @apply text-white hover:text-white;
  @apply focus:ring-amber-300;
}
.btn-info{
  @apply bg-sky-600 hover:bg-sky-700 shadow-sm;
  @apply text-white hover:text-white;
  @apply focus:ring-sky-600;
}
