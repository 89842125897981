

/*
  Underline child links beneath this class
*/
.links-underline{
  a{
    @apply underline;
  }
}
.links-no-underline{
  a{
    @apply no-underline;
  }
}


.item-data-table{
  th, td{
    @apply py-1 md:py-0 lg:py-1;
  }
  tr{
    @apply border-b border-dashed border-slate-300;
    @apply last:border-0;
  }
  th{
    @apply font-semibold;
    @apply md:block md:pt-1;
    @apply lg:table-cell lg:w-2/5;
  }
  td{
    @apply md:block md:pl-3 md:py-1;
    @apply lg:table-cell lg:w-3/5 lg:pl-0;
  }
}
.item-data-table--cleared{
  tr{
    @apply lg:border-0;
    @apply lg:last:border-0;
  }
}


.seoDataContentStyling{
  h2, h3, h4{
    @apply font-semibold text-xl mt-1.5;
  }
  p{
    @apply mb-2.5;
  }
  ul{
    @apply list-disc ml-3.5 mb-2;
  }
  li{
    @apply mb-0.5;
  }
}

.seoDataContentStyling--category{
  h2, h3, h4{
    @apply font-semibold mt-1;
  }
  p{
    @apply mb-1.5;
  }
  ul{
    @apply list-disc ml-3.5 mb-2;
  }
  li{
    @apply mb-0.5;
  }
}


.js__filterButton.active{
  @apply shadow-inner shadow-neutral-800/20 bg-slate-200/80;
}


/*
  Class wrapper for static pages like ToS/AGB etc

  📌 Please only use it when you cannot access DOM
  elements like the parsed/included HTML files.
*/
.static-pages-classes{
  h1, h2{
    @apply mb-1.5 text-xl font-semibold;
    @apply md:text-2xl;
  }
  h2, h3{
    @apply text-lg mb-1;
  }
  h1:not(:first-child){
    @apply mt-6;
  }
  h2{
    @apply mt-4;
  }

  p{
    @apply my-1.5;
  }

  ol,ul{
    @apply list-disc ml-4;
  }

  table{
    @apply table-auto border-collapse my-5;

    & td{
      @apply p-2 border border-neutral-400;
    }
  }
}
