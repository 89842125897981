
/*
	+ + + + + + + + + + + + + +
	To be sorted into partials:
	+ + + + + + + + + + + + + +
*/

/*
	Main Nav
	👉 Needs a `hover:after:block` on the enclosing parent element

	Pseudo element for some movement tolerance while moving
	in a sloped way intoto the dropdown
*/
.nav-main-pseudo-clip::after{
  /* @apply bg-red-200 block; */
  @apply hidden;
  @apply -bottom-1 right-0 absolute -mr-12 w-12 h-[110%] z-50;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}


/*
	Swiper next/prev arrows
*/
.swiper-button-next::after,
.swiper-button-prev::after{
  @apply text-2xl md:text-4xl;
}


/*
	Ad above the image inside the lightbox-gallery of an item
*/
#item-view-gallery__reklame {
  position: fixed;
  top: 44px;
  left: 0;
  width: auto;
  height: 90px;
  margin: 0;
  padding: 0;
  z-index: 20010;
  text-align: center;
}


/*
	Sets some basic styling on the pagination which is used in
	legacy and tailwind versions together
*/
.pagination.pager-modern{
  @apply mt-4 mb-4 text-center text-[13px];

  li{
    @apply inline-block mb-2.5;
  }
  a{
    @apply px-3 py-1 bg-neutral-100 rounded-sm;
    @apply no-underline transition-colors;
    @apply hover:bg-mtb hover:text-mtb-100;
  }
  li:not(.pager-modern-item-prev) .active{
    @apply bg-mtb text-mtb-100;
  }
}


/*
	Styling for the infinite scrolling hint
*/
.mtbnews-infinite-scroll__loading{
  @apply text-center text-mtb font-bold;
}


/*
	Background-color for an items status below the title
	!! Need to be included here, because of dynamic creation and therefore purged...
*/
.item__status--1 {
  background-color: #ff0;
}
.item__status--2 {
  background-color: #cf6;
}
.item__status--3 {
  background-color: #777;
  color: #fff
}
.item__status--4 {
  background-color: #fc8;
}
.item__status--5 {
  background-color: #f00;
}
.item__status--6 {
  background-color: #f00;
}
.item__status--65 {
  background-color: #ff99f1;
}
.item__status--66 {
  background-color: #66ccff;
}
.item__status--67 {
  background-color: #d4582c;
}
.item__status--200 {
  background-color: #aa00ff;
  color: #fff;
}
.item__status--210 {
  background-color: #ff0000;
}


/*
	Tooltips (imported from legacy)
*/
.tooltip{
  --tooltipArrowWidth: 5px;
  --tooltipArrowColor: black;

  @apply absolute block visible z-50;
  @apply text-[11px] leading-snug opacity-0;

  &.in{
    @apply opacity-80;
  }
  &.top    { margin-top:  -3px; padding: 5px 0; }
  &.right  { margin-left:  3px; padding: 0 5px; }
  &.bottom { margin-top:   3px; padding: 5px 0; }
  &.left   { margin-left: -3px; padding: 0 5px; }

  &.top .tooltip-arrow {
    @apply bottom-0 left-1/2;
    margin-left: -var(--tooltipArrowWidth);
    border-width: var(--tooltipArrowWidth) var(--tooltipArrowWidth) 0;
    border-top-color: var(--tooltipArrowColor);
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -var(--tooltipArrowWidth);
    border-width: var(--tooltipArrowWidth) var(--tooltipArrowWidth) var(--tooltipArrowWidth) 0;
    border-right-color: var(--tooltipArrowColor);
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -var(--tooltipArrowWidth);
    border-width: var(--tooltipArrowWidth) 0 var(--tooltipArrowWidth) var(--tooltipArrowWidth);
    border-left-color: var(--tooltipArrowColor);
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -var(--tooltipArrowWidth);
    border-width: 0 var(--tooltipArrowWidth) var(--tooltipArrowWidth);
    border-bottom-color: var(--tooltipArrowColor);
  }
}

.tooltip-inner {
  @apply bg-black px-2 py-0.5;
  @apply text-center text-white no-underline;
  max-width: 230px;
}

.tooltip-arrow {
  @apply absolute w-0 h-0 border-transparent border-solid;
}



/*
	Autosuggest results via jQuery plugin
*/
.autocomplete-suggestions {
  @apply border border-neutral-800 bg-white overflow-hidden text-sm;

  & strong {
    @apply font-semibold text-mtb;
  }
}
.autocomplete-suggestion {
  @apply px-3 py-1 overflow-clip whitespace-nowrap;
}
.autocomplete-selected {
  @apply bg-neutral-200;
}
.autocomplete-group {
  @apply px-3 py-1;

  & strong {
    @apply block border-b border-neutral-800;
  }
}


/*
	Filepond-Uploader
*/
.filepond--root {
  @apply mb-0;
}
.filepond--credits {
  @apply hidden;
}
.filepond--drip {
  @apply bg-slate-300 rounded-none;
}
.filepond--panel-root{
  @apply rounded-none;
}

.filepond--image-preview-overlay svg rect {
  /* remove the gradient */
  display: none;
}
.filepond--image-preview{
  @apply bg-gradient-to-br from-slate-600 to-slate-400;
}
.filepond--image-preview-overlay-idle{
  @apply bg-none;
}
.filepond--image-preview-overlay-success{
  @apply bg-lime-300/30 mix-blend-overlay;
}
.filepond--image-preview-overlay-failure{
  @apply bg-red-500/30 mix-blend-overlay;
}


/*
	Leaflet overrides
*/
.leaflet-bottom{
  /*
  original z-index of 1000 clashes with our z-indexes and
  'auto' seems to work for the map copyright container
  */
  z-index: auto;
}
