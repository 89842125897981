html, body{
  @apply text-[15px] font-sans;
}


/*
  AlpineJS helper to hide 'cloaked' elements
  @url https://alpinejs.dev/directives/cloak
*/
[x-cloak]{
  @apply hidden;
}


/*
  Default link style
*/
a{
  @apply underline decoration-current decoration-from-font;

  &:hover{
    @apply text-mtb-700 decoration-mtb-300;
  }
}


/*
  Align tablerows to the top
*/
th{
  @apply font-normal;
}
tr{
  @apply align-top;
}


/*
  Removes rounding, shadows and border
  from iOS search field inputs
*/
input[type="search"] {
  @apply !appearance-none;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.35);
}
