/*
  Ad container and styles
 */

/* banner above everything  */
.header__banner {
  @apply relative text-center;
  @apply bg-white;

  @apply md:min-h-[90px];
  @apply lg:min-h-[250px];
}


/* right banner - shown above 1170px width */
#sky-banner {
  @apply hidden absolute top-0;
}
@media screen and (min-width: 1170px) {
  #sky-banner{
    @apply block -right-[162px] w-[160px] h-[600px];
  }
}


/* left banner - shown above 1440px width  */
#sky-banner-left {
  @apply hidden absolute top-0 text-right;
}
@media (min-width: 1440px) {
  #sky-banner-left {
    @apply block -left-[300px] w-[300px];
  }
}
@media (min-width: 1590px) {
  #sky-banner-left {
    @apply -left-[306px];
  }
}


/*
  non-optimised classes below...
  (but stripped classes that are now above)
*/

.mtbnews-offcontent__left {
  text-align: right;

  @media (min-width: 1140px) {
    margin-bottom: 1500px;
  }
}

/* https://stackoverflow.com/a/9618795 */
.mbm-jumptarget::before {
  content: "";
  display: block;
  height: 100px;
  margin-top: -100px;
}

.mtbnews-mobile-sticky {
  height: 50px;
  max-height: 50px;
}

#MTB_M_Markt_Sticky {
  width: 100vw;
  height: 50px;
  max-height: 50px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.40);
}

#MTB_D_Markt_Offcontent_Left-3 {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  /* https://stackoverflow.com/a/9618795 */
  .mbm-jumptarget::before {
    content: "";
    display: block;
    height: 0;
    margin-top: 0;
  }

  .mtbnews-mobile-sticky,
  #MTB_M_Markt_Sticky {
    height: 0;
    max-height: 0;
  }
}

.mtbnews-b-mobile {
  text-align: center;
  margin-bottom: 20px;
}


.incontent__container {
  text-align: center;
  position: relative;
  min-height: 600px;

  &--desktop {
    min-height: 0;
  }
}

.incontent__inner {
  text-align: center;
  position: sticky;
  top: 0;
}

@media (min-width: 768px) {
  .incontent__container {
    min-height: 280px;

    &--mobile {
      min-height: 0;
    }
  }

  .incontent__inner {
    position: relative;
  }
}


/*
  Ads isnide/between listings
*/
.productsList {
  .interjection {
    min-height: 620px;
    text-align: center;
    padding-bottom: 5px;
    position: relative;
  }

  .interjection__desktop {
    min-height: 0;
  }

  @media (min-width: 768px) {
    .interjection,
    .interjection__desktop {
      min-height: 280px;
    }
  }

  .interjection__inner {
    position: sticky;
    top: 50px;
  }
}
