/*
	Layout & main container styling
 */


/* 	Main wrapper for the page */
.bikemarkt-wrapper {
	@apply container relative ml-0;
}
@media screen and (min-width: 1440px) {
	.bikemarkt-wrapper {
		@apply ml-[160px];
  }
}
@media screen and (min-width: 1580px) {
	.bikemarkt-wrapper {
  	@apply mx-auto;
  }
}
